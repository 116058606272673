
@import 'variables';

@import '~bootstrap/scss/bootstrap';



.bg-primary {
  background-color: $brand-primary !important;
}

h1, h2, h3, h4, h5 {
  font-family: 'Montserrat', sans-serif;
}


h1 {
  font-size: 1.75rem;
}

.main-header {
  width: 100%;
  min-height: 300px;
  background-size: cover;
  background-position: center;

  @include media-breakpoint-up(lg) {
    min-height: 500px;
  }
}

.nav-tagline {
  padding-left: 20px;
  font-size: 1rem;
  font-style: italic;
}


.main-input-title {
  @extend h3;
  text-align: center;
  width: 100%;
  display: block;
  color: $white;
  font-weight: 700;
  text-shadow: 1px 1px $black;
}

.full-width{
  width: 100%;
}

.form-control {

}

a {
  color: $brand-primary;
  text-decoration: underline;

  &:hover {
    color: $black;
  }
}

.btn {
  border-radius: 0;
  &.btn-primary {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: $black;
    text-decoration: none;

    &.reversed {
      color: $brand-primary;
      background-color: $black;

      &:hover, &:focus, &.active {
        background-color: $brand-primary;
        border-color: $brand-primary;
        color: $black;
      }

      &.no-hover {
        &:hover, &:focus, &.active {
          color: $brand-primary;
          background-color: $black;
        }
      }
    }

    &:hover, &:focus, &.active {
      background-color: $black;
      border-color: $black;
      color: $brand-primary;
    }


  }
}

.bg-sneek {
  background-color: $black;
  color: $brand-primary;
}

.bg-grey {
  background-color: $gray-100;
}

.input-group-text {
  &.bg-sneek {
    border-color: $black;
  }
}

.rounded-icon {
  background-color: $brand-primary;
  color: $black;
  display: block;
  border-radius: 10000px;

  &.width-50 {
    width: 50px;
    height: 50px;
    border-radius: 50px !important;
    padding-top: 12.5px;
    text-align: center;
  }

  &.width-100 {
    width: 100px;
    height: 100px;
    border-radius: 100px !important;
    padding-top: 30px;
    text-align: center;
  }
}

.history-mark {
  float: right;
  background: $black;
  color: $brand-primary;
  padding: 15px;
  display: inline-block;
  z-index: 2;
  position: relative;

  &:after {
    height: 1px;
    width: 90px;
    background: $black;
    content: '';
    left: 100%;
    display: block;
    position: absolute;
    top:25%;
  }
}

.history-text {
  border-left: 1px solid $black;
  padding-left: 15px;
  position: relative;
  background-color: $white;
  z-index: 3;
  margin-bottom: 30px;


}

.logo {
  z-index: 3;
  position: relative;
  max-width: 200px;
}

.banner {
  position: absolute;

  background-color: $brand-primary;
  padding: 15px;
  font-size: $small-font-size;
  transform: rotate(-3deg);
  top: 110px;
  left: 30%;

  @include media-breakpoint-up(md) {
    top: 47px;
    left: 25px;
    width: 200px;
  }
}

.logo-text {
  h3 {
    font-size: 14px;
    text-align: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      text-align: left;
      font-size: 16px;
    }

  }
}

.rocket {
  max-width: 200px;
  margin-top: -415px;
  position: relative;
  z-index: 4;

  @include media-breakpoint-up(sm) {
    margin-top: -375px
  }

  @include media-breakpoint-up(md) {
    max-width: 400px;
    margin-top: -175px;
  }
}